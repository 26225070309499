import { render } from 'react-dom'
import { StyledEngineProvider } from '@mui/material/styles'
import '@mui/styles'
import App from './App'
import { ThemeProvider } from './contexts/theme'
import './index.css'

render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
)
