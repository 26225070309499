const header = {
  homepage: '/',
  title: 'AP.',
}

const about = {
  name: 'Alexandre Pinel',
  role: 'Front End Engineer and Linguist',
  description: `I have been coding and learning languages since the 90's. My passions have become and are my job and I love what I do. Let's connect!`,
  resume: '',
  social: {
    linkedin: 'https://www.linkedin.com/in/alexandre-pinel-045807128/',
    github: 'https://github.com/HaoLiHaiO',
    twitter: 'https://twitter.com/HereticRamza',
    upwork: 'https://www.upwork.com/freelancers/alexandrepinel',
    proz: 'http://www.proz.com/profile/2230187'
  },
}

const projects = [
  {
    name: '',
    description:
      'Visit my Github for personal projects and code snippets. Contact me to know more about my private professional projects. I may not be able to show you all of them. As Renan Moura says: "if you ask me for fully coded systems to show, I won’t have any since they belong to someone that hired me." ',
    stack: [],
    sourceCode: 'https://github.com/HaoLiHaiO',
    livePreview: ''
  },
]

const skills = [
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Material UI',
  'styled-components',
  'Git',
  'CI/CD',
  'Jest',
  'Enzyme',
  'Cypress',
  'Storybook'
]

const contact = {
  email: 'alexandre@alexandrepinel.com'
}

export { header, about, projects, skills, contact }
