import About from "../About/About"
import Projects from "../Projects/Projects"
import Skills from "../Skills/Skills"
import Contact from "../Contact/Contact"

const Homepage = () => (
  <>
    <About />
    <Projects />
    <Skills />
    <Contact />
  </>
)

export default Homepage
