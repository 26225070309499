/**
 *
 * @todo remove construction page CSS later
 */
const Services = () => (
  <div className='section center services'>
    <h3>Page under construction. 👷</h3>
    <h3 className='waving'>🔨</h3>
  </div>
)

export default Services
