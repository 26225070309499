import { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Services from './components/Services/Services'
import Footer from './components/Footer/Footer'
import Homepage from './components/Homepage/Homepage'
import NotFound from './components/NotFound/NotFound'
import './App.css'

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)

  return (
    <BrowserRouter>
      <div id='top' className={`${themeName} app`}>
        <Header />

        <main>
          <Routes>
            <Route exact path='/'>
              <Route path='/' element={<Homepage />} />
            </Route>
            <Route path='/services'>
              <Route exact path='/services' element={<Services />} />
            </Route>
            <Route path='*' exact element={<NotFound />} />
          </Routes>
        </main>

        <ScrollToTop />
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
