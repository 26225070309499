import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import UpworkIcon from '../../assets/images/Upwork'
import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, description, resume, social } = about

  return (
    <div className='about center'>
      {name && (
        <h1>
          Hi, I am <span className='about__name'>{name}.</span>{' '}
          <span className='waving'>👋</span>
        </h1>
      )}

      {role && <h2 className='about__role'>A {role}.</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        {resume && (
          <a href={resume} target='_blank' rel='noopener noreferrer'>
            <span type='button' className='btn btn--outline'>
              Resume
            </span>
          </a>
        )}

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <GitHubIcon />
              </a>
            )}

            {social.upwork && (
              <a
                href={social.upwork}
                aria-label='upwork'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <UpworkIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <LinkedInIcon />
              </a>
            )}

            {social.twitter && (
              <a
                href={social.twitter}
                aria-label='twitter'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <TwitterIcon />
              </a>
            )}

            {social.proz && (
              <a
                href={social.proz}
                aria-label='upwork'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  className='proz'
                  src='https://cfcdn.proz.com/images/affiliates/proz_translation_workplace.gif'
                  alt='ProZ linguist profile'
                />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
